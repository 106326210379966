import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Upload } from '../../../assets/upload.svg';
import { fileUpload } from '../../../reducer';

export default function FileUploadWidget({ value }) {
  const [error, setError] = useState('');
  const [file, setFile] = useState('');
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();

  const fileTypes = ['jpeg', 'jpg', 'png', 'pdf', 'gif', 'txt'];

  const handleClick = () => {
    setError('');
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      if (!fileTypes.includes(fileUploaded.name.split('.').pop())) {
        setError(
          `.${fileUploaded.name
            .split('.')
            .pop()} file extension is not supported`
        );
      } else if (fileUploaded.size / 1000000 > 5) {
        setError('File exceeding maximum size');
      } else {
        setFile(fileUploaded.name);
        dispatch(
          fileUpload({
            file: fileUploaded,
            requestId: value.requestId,
            uploadToken: value.uploadToken
          })
        );
      }
    }
  };

  return (
    <FileUpload onClick={handleClick}>
      <UploadIcon />
      <Title>Upload Document</Title>
      <SubTitle>5MB Maximum</SubTitle>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {file && <ConfirmMessage>{file}</ConfirmMessage>}
    </FileUpload>
  );
}

FileUploadWidget.propTypes = {
  value: PropTypes.shape({
    requestId: PropTypes.string.isRequired,
    uploadToken: PropTypes.string.isRequired
  })
};

const FileUpload = styled.div`
  border-radius: 10px;
  background: #fbfbfb;
  border: 3px dashed #eeeeee;
  margin: 5px 5px 5px 0px;
  padding: 8px 12px;
  word-break: break-word;
  cursor: pointer;
`;

const UploadIcon = styled(Upload)`
  width: 50px;
  height: 50px;
  margin: 0 auto 5px auto;
  display: block;
`;

const Title = styled.p`
  text-align: center;
  margin: 0 0 5px 0;
`;

const SubTitle = styled.p`
  text-align: center;
  font-size: 10px;
  margin: 0 0 5px 0;
`;

const ErrorMessage = styled(SubTitle)`
  text-align: center;
  font-size: 10px;
  margin: 0 0 5px 0;
  color: red;
`;

const ConfirmMessage = styled(SubTitle)`
  text-align: center;
  font-size: 10px;
  margin: 0 0 5px 0;
  color: green;
`;
