import {
  fetchFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage
} from '../utils/sessionStorage';
import {
  fetchAndSaveAnonymousIDtoStorage,
  getAnonymousID
} from './anonymousID';
import { storeFile } from './storageAPI';
import emitter from '../../emitter';

const CompleteChatReasons = {
  ENDED_CHAT: 'User ended chat',
  RESET: 'User reset chat'
};

export default class SocketHelper {
  constructor(
    io,
    url,
    client,
    pipeline,
    dispatch,
    createMsgAction,
    enableTypingIndicatorAction,
    disableTypingIndicatorAction,
    agentJoinedChatAction,
    agentLeftChatAction,
    fileUploadRequestAction,
    fileUploadAction,
    fileUploadSuccessAction,
    fileUploadErrorAction,
    debug,
    metadata,
    authentication,
    appName
  ) {
    this.url = url;
    this.client = client;
    this.dispatch = dispatch;
    this.pipeline = pipeline;
    this.io = io;
    this.metadata = metadata ? metadata : {};
    this.authentication = authentication;
    this.debug = debug || false;
    this.createMsgAction = createMsgAction;
    this.enableTypingIndicatorAction = enableTypingIndicatorAction;
    this.disableTypingIndicatorAction = disableTypingIndicatorAction;
    this.agentJoinedChatAction = agentJoinedChatAction;
    this.agentLeftChatAction = agentLeftChatAction;
    this.fileUploadRequestAction = fileUploadRequestAction;
    this.fileUploadAction = fileUploadAction;
    this.fileUploadSuccessAction = fileUploadSuccessAction;
    this.fileUploadErrorAction = fileUploadErrorAction;
    this.appName = appName;
  }

  async createSocketConnection() {
    this.creatingConnection = true;

    try {
      const anonymous_id = await getAnonymousID();

      const queryStringParameters = {
        debug: this.debug,
        client: this.client,
        anonymous_id: anonymous_id,
        parentPage: window.location.href,
        channel: JSON.stringify({
          type: 'messenger',
          parent_page: window.location.href,
          languageCode:
            window.navigator.language || window.navigator?.languages[0],
          userAgent: window.navigator.userAgent
        })
      };

      const opts = {
        path: '/api/socket.io',
        query: queryStringParameters,
        transports: ['websocket'] // See EBIAIRD-1543 for reason behind using socket io with raw websockets and no fallback
      };

      const namespace = '/api/livechat';
      const socket = this.io.connect(`${this.url}${namespace}`, opts);

      this.socket = socket;
      this.listenToSocketEvents(socket);
    } finally {
      this.creatingConnection = false;
    }
  }

  createChat(reconnectBot) {
    const chatId = fetchFromSessionStorage(
      `${this.client}/${this.pipeline}/chatId`
    );

    if (chatId) {
      this.chatId = chatId;
      this.socket.emit('watch-chat', {
        chat_id: this.chatId
      });
    } else {
      this.socket.emit('create-chat', {
        pipeline_id: this.pipeline,
        metadata: this.metadata,
        reconnect_bot: reconnectBot ? reconnectBot : false
      });
    }
  }

  checkStatus() {
    if (!this.socket && !this.creatingConnection) {
      this.createSocketConnection();
    }
  }

  inChatConversation() {
    return this.chatId;
  }

  listenToSocketEvents(socket) {
    socket.on('connect', () => {
      if (!this.inChatConversation()) {
        this.createChat();
      }
    });

    socket.on('reconnect', () => {
      this.createChat();
    });

    socket.on('connect_failed', () => {
      console.log('Connection Failed'); // eslint-disable-line no-console
    });

    socket.on('debug', data => {
      // eslint-disable-next-line no-console
      console.log('DEBUG', data);
    });

    socket.on('message', message => {
      this.onMessage(message);
    });

    socket.on('file', payload => {
      this.onFile(payload);
    });

    socket.on('joined-chat', message => {
      this.onMessage(message);
    });

    socket.on('invitation', message => {
      this.onMessage(message);
    });

    socket.on('left-chat', message => {
      this.onMessage(message);
    });

    socket.on('complete-chat', message => {
      try {
        if (message?.value?.reason === CompleteChatReasons.RESET) {
          this.createChat();
        } else {
          removeFromSessionStorage(`${this.client}/${this.pipeline}/chatId`);
          const existingChat = fetchFromSessionStorage(
            `lobster/${this.client}/${this.pipeline}/state/${this.appName}`
          );
          if (existingChat) {
            const chatData = JSON.parse(existingChat);
            const hasUserMessage = chatData.chat.messages.some(
              messageItem => messageItem.owner === 'self'
            );

            this.onMessage({
              ...message,
              value: {
                ...message.value,
                hasUserMessage
              }
            });
          }
        }
      } catch (e) {
        this.onMessage(message);
      }
    });

    socket.on('reconnect-bot', () => {
      const reconnectBot = true;
      removeFromSessionStorage(`${this.client}/${this.pipeline}/chatId`);
      this.createChat(reconnectBot);
    });

    socket.on('chat-created', chatId => {
      saveToSessionStorage(`${this.client}/${this.pipeline}/chatId`, chatId);
      emitter.emit('chat-created');
      this.chatId = chatId;
      this.socket.emit('authentication', {
        chat_id: this.chatId,
        authentication: this.authentication
      });
    });

    socket.on('error', async error => {
      const existingChat = fetchFromSessionStorage(
        `${this.client}/${this.pipeline}/chatId`
      );
      const existingAnon = fetchFromSessionStorage('anonymousID');

      if (
        error.type === 'anonymous_id_invalid' &&
        !existingChat &&
        !existingAnon
      ) {
        removeFromSessionStorage('anonymousID');
        removeFromSessionStorage(`${this.client}/${this.pipeline}/chatId`);
        await fetchAndSaveAnonymousIDtoStorage();
        this.createSocketConnection();
      } else if (existingAnon && existingChat) {
        this.socket.emit('watch-chat', {
          chat_id: existingChat
        });
      }
    });

    socket.on('started-typing', startedTypingPayload => {
      const payload = (({ user, date }) => ({ user, date }))(
        startedTypingPayload
      );
      this.dispatch(this.enableTypingIndicatorAction(payload));
    });

    socket.on('stopped-typing', stoppedTypingPayload => {
      const payload = (({ user, date }) => ({ user, date }))(
        stoppedTypingPayload
      );
      this.dispatch(this.disableTypingIndicatorAction(payload));
    });

    socket.on('client-error', err => console.log('client error', err)); // eslint-disable-line no-console
    socket.on('server-error', err => console.log('server error', err)); // eslint-disable-line no-console
  }

  emitMessage(msg) {
    const existingAnon = fetchFromSessionStorage(`anonymousID`);
    const existingChat = fetchFromSessionStorage(
      `${this.client}/${this.pipeline}/chatId`
    );

    if (!existingChat) {
      this.createChat(!!existingAnon);

      this.socket.on('chat-created', chatId => {
        this.socket.emit('message', {
          chat_id: chatId,
          type: 'text',
          value: msg.value
        });
      });
    } else {
      this.socket.emit('message', {
        chat_id: this.chatId,
        type: 'text',
        value: msg.value
      });
    }
  }

  emitStartedTyping() {
    this.socket.emit('started-typing', {
      chat_id: this.chatId
    });
  }

  emitStoppedTyping() {
    this.socket.emit('stopped-typing', {
      chat_id: this.chatId
    });
  }

  emitCompleteChat(config = {}, reason = CompleteChatReasons.ENDED_CHAT) {
    this.socket.emit('complete-chat', {
      chat_id: this.chatId,
      reason,
      config
    });
  }

  onMessage(message) {
    if (this.inChatConversation() === message.chat_id) {
      this.dispatch(this.createMsgAction(this.transformMessage(message)));

      if (message.etype === 'joined-chat') {
        this.dispatch(this.agentJoinedChatAction(message.value));
      } else if (message.etype === 'left-chat') {
        this.dispatch(this.agentLeftChatAction(message.value));
      }
    }
  }

  onFile(message) {
    if (message.type === 'file-request') {
      this.dispatch(
        this.fileUploadRequestAction(this.transformMessage(message))
      );
    }
    if (message.type === 'file-upload-success') {
      this.dispatch(
        this.fileUploadSuccessAction(this.transformMessage(message))
      );
    }
    if (message.type === 'file-upload-error') {
      this.dispatch(this.fileUploadErrorAction(this.transformMessage(message)));
    }
  }

  async emitFile({ file, requestId, uploadToken }) {
    const url = await storeFile(file, uploadToken, this.client);
    this.socket.emit('file', {
      chat_id: this.chatId,
      type: 'file-upload',
      value: {
        name: file.name,
        requestId,
        url
      }
    });
  }

  async resetChat() {
    removeFromSessionStorage(`${this.client}/${this.pipeline}/chatId`);
    this.emitCompleteChat(
      {
        reconnectBot: false
      },
      CompleteChatReasons.RESET
    );
    this.chatId = null;
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  transformMessage(socketMessage) {
    return {
      ...socketMessage,
      owner: socketMessage.user,
      type: [
        'joined-chat',
        'complete-chat',
        'left-chat',
        'invitation',
        'channel-details'
      ].includes(socketMessage.etype)
        ? socketMessage.etype
        : socketMessage.type
    };
  }
}
