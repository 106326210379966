import React from 'react';
import PropTypes, { string, object } from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { useSelector } from 'react-redux';

import cssOverrides from '../../utils/cssOverrides';
import { decodeSpecialChars } from '../../utils/decodeSpecialChars';
import Linkify from 'react-linkify';
import { ReactComponent as File } from '../../assets/file.svg';

export default function UserMessage(props) {
  const theme = useSelector(state => state.config.theme);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const overrides = theme.cssOverrides?.UserMessage || {};
  switch (props.type) {
    case 'file':
      return (
        <SelfBubbleFile theme={theme} css={overrides} animate={props.animate}>
          <a href={props.value.url} rel="noopener noreferrer" download>
            <FileIcon /> {decodeSpecialChars(props.value.name)}
          </a>
        </SelfBubbleFile>
      );
    case 'text':
    default:
      return (
        <SelfBubble
          theme={theme}
          css={overrides}
          animate={props.animate}
          fullScreen={fullScreen}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {decodeSpecialChars(props.title ? props.title : props.value)}
          </Linkify>
        </SelfBubble>
      );
  }
}

UserMessage.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([string, object]).isRequired,
  animate: PropTypes.bool.isRequired
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const SelfBubble = styled.p`
  background-color: ${props =>
    props.theme.userMessageColour?.background ||
    props.theme.primaryColour ||
    '#2574D4'};
  color: ${props => props.theme.userMessageColour?.text || 'white'};
  line-height: 1.3em;
  margin: 2px;
  max-width: 75%;
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          @media screen and (min-width: 950px) {
            max-width: 65%;
          }
        `
      : null};
  width: fit-content;
  padding: 10px 14px;
  border-radius: 10px 6px 6px 10px;
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 100% 100%;
  transition: border-radius 300ms;
  word-break: break-word;
  white-space: pre-wrap;
  a {
    color: inherit;
  }
  ${cssOverrides}

  &:first-of-type {
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
  }
`;

const SelfBubbleFile = styled(SelfBubble)`
  & a {
    color: white;
    text-decoration: none;
    position: relative;
    top: -6px;
  }
`;

const FileIcon = styled(File)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: white;
  fill: white;
  position: relative;
  top: 7px;
`;
